const { AxiosConnect } = require("./axios.connect");

export class Distribution {
  async getAirdropDetails(airdrop_id) {
    const { data: response } = await AxiosConnect.get(
      `/distribution/details/${airdrop_id}`
    );
    if (response && response.data) {
      return response;
    }
  }

  async getAirdropDetailsForTx(airdrop_id, percentage) {
    const { data: response } = await AxiosConnect.get(
      `/distribution/tx-details/${airdrop_id}/${percentage}`
    );
    if (response && response.data) {
      return response;
    }
  }

  async confirmTxByAdmin(airdrop_id) {
    const { data: response } = await AxiosConnect.post(`/distribution/confirm-tx`, { airdrop_id });
    if (response && response.data) {
      return response;
    }
  }

  async confirmBoc(airdrop_id, boc) {
    const { data: response } = await AxiosConnect.post(`/distribution/confirm-boc`, { airdrop_id, boc });
    if (response && response.data) {
      return response;
    }
  }

  async approveTx(data) {
    const { data: response } = await AxiosConnect.post(`/distribution/approve-tx`, data);
    if (response && response.data) {
      return response;
    }
  }

  handleTheError(AxiosError) {
    let errorMessage =
      AxiosError.response?.data?.message ||
      AxiosError.message ||
      "Request failed";
    console.error("Error message:", errorMessage);
    return errorMessage;
  }
}
