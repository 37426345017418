import React, { useState, useEffect } from "react";
import { Distribution } from "../connect/distribution.connect";
import { useParams } from "react-router-dom";

function DistributionList() {
  const [airdrop, setAirdrop] = useState(null);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const { airdropId } = useParams();
  const distributionService = new Distribution();

  const fetchAirdropDetails = async () => {
    try {
      const { data: response } = await distributionService.getAirdropDetails(airdropId);
      console.log({ fetchAirdropDetails: response });
      setAirdrop(response.airdrop);
      setUsers(response.users);
    } catch (error) {
      const errorMessage = distributionService.handleTheError(error);
      alert(errorMessage);
      console.log({ errorMessage });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAirdropDetails();
  }, [airdropId]);

  const filterUsers = (query) => {
    const lowercasedQuery = query.toLowerCase();
    return users.filter(user => {
      const userName = user.userDetail?.username?.toLowerCase() || '';
      const userCoin = user.userDetail?.coin?.toString().toLowerCase() || '';
      const userProfitPerHour = user.userDetail?.profitPerHour?.toString().toLowerCase() || '';

      return (
        userName.includes(lowercasedQuery) ||
        userCoin.includes(lowercasedQuery) ||
        userProfitPerHour.includes(lowercasedQuery)
      );
    });
  };

  const filteredUsers = filterUsers(searchQuery);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!airdrop) {
    return <div>Airdrop not found</div>;
  }

  return (
    <div className="max-w-md mx-auto mt-10">
      <p className="text-white font-bold text-center text-[40px] mb-2">
        Airdrop Distribution Details
      </p>
      <div className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg p-4 dark:bg-gray-700 dark:border-gray-600 dark:text-white">
        <div className="flex items-center mb-2">
          <img
            src={airdrop.logo}
            alt={airdrop.name}
            className="w-10 h-10 rounded-full mr-4"
          />
          <div>
            <p className="text-lg font-semibold">{airdrop.name}</p>
            <p className="text-sm text-gray-500 dark:text-gray-400">
              {airdrop.description}
            </p>
          </div>
        </div>
        <img
          src={airdrop.image}
          alt={airdrop.name}
          className="w-full h-40 object-cover mb-4 rounded"
        />
        <p className="mb-2">Status: {airdrop.status}</p>
        <p className="mb-2">Verified: {airdrop.verified ? "Yes" : "No"}</p>
        <p className="mb-2">
          Profit Per Hour: {(airdrop?.profitPerHour ?? 0).toLocaleString()}
        </p>
        <p className="mb-2">
          Symbol: <span className="font-bold">{airdrop.symbol}</span>
        </p>
        <p className="mb-2">
          Reward Pool: {(airdrop?.rewardPool ?? 0).toLocaleString()}
        </p>
        <p className="mb-2">
          Reward Pool Amount:{" "}
          {(airdrop?.rewardPoolAmount ?? 0).toLocaleString()}
        </p>
        <p className="mb-2">
          Total Raiders: {(airdrop?.totalUsers ?? 0).toLocaleString()}
        </p>
        <p className="mb-2">
          Total Completed Raiders: {(airdrop?.totalUsersCompleted ?? 0).toLocaleString()}
        </p>
        <p className="mb-2">Cost: {(airdrop?.cost ?? 0).toLocaleString()}</p>
        <p className="mb-2">
          Start Date: {new Date(airdrop.startDate).toLocaleString()}
        </p>
        <p className="mb-2">
          End Date: {new Date(airdrop.endDate).toLocaleString()}
        </p>

        {/* Search Input */}
        <input
          type="text"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          placeholder="Search by name, coin, profit per hour"
          className="w-full mb-4 px-4 py-2 border rounded-lg dark:bg-gray-800 dark:border-gray-600 dark:text-white"
        />

        <div className="mb-4">
          <p className="font-semibold text-lg mb-2">Users in Airdrop:</p>
          <div className="bg-gray-100 dark:bg-gray-800 rounded-lg p-4">
            <ul className="space-y-2">
              {filteredUsers.map((user) => (
                <li key={user._id} className="border-b border-gray-300 pb-2">
                  <div className="flex justify-between items-center">
                    <div>
                      <p className="font-medium text-base">
                        {user.userDetail?.username || "Unknown User"}
                      </p>
                      <p className="text-sm text-gray-600 dark:text-gray-400">
                        Status: <span className="font-semibold">{user.status}</span>
                      </p>
                    </div>
                    <div>
                      <p className="text-sm text-gray-600 dark:text-gray-400">
                        Coin: <span className="font-semibold">{user.userDetail?.coin?.toLocaleString()}</span>
                      </p>
                      <p className="text-sm text-gray-600 dark:text-gray-400">
                        Profit Per Hour: <span className="font-semibold">{user.userDetail?.profitPerHour?.toLocaleString()}</span>
                      </p>

                      <p className="text-sm text-gray-600 dark:text-gray-400">
                        Referral Count: <span className="font-semibold">{user.userDetail?.referralCount?.toLocaleString()}</span>
                      </p>
                    </div>
                  </div>
                  <div className="mt-2 flex justify-between">
                    <p className="text-sm">
                      User Approved:{" "}
                      <span className={`font-semibold ${user.userApproved ? "text-green-600" : "text-red-600"}`}>
                        {user.userApproved ? "Yes" : "No"}
                      </span>
                    </p>
                    <p className="text-sm">
                      Admin Approved:{" "}
                      <span className={`font-semibold ${user.adminApproved ? "text-green-600" : "text-red-600"}`}>
                        {user.adminApproved ? "Yes" : "No"}
                      </span>
                    </p>
                  </div>
                </li>
              ))}
            </ul>
            {filteredUsers.length === 0 && (
              <p className="text-sm text-gray-600 dark:text-gray-400 mt-4">No users match the search criteria.</p>
            )}
          </div>
        </div>

        <div className="flex justify-between mt-4">
          <a
            href={`/airdrop/edit/${airdrop.airdrop_id}`}
            className="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
          >
            Edit
          </a>
          <a
            href={`/distribute-page/${airdrop.airdrop_id}`}
            className="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
          >
            Distribution
          </a>
        </div>
      </div>
    </div>
  );
}

export default DistributionList;
