import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ProjectConnect } from "../../connect/project.connect";

function QuestEdit() {
  const quest = new ProjectConnect();
  const { questId } = useParams();
  const [btnTxt, setBtnTxt] = useState("Edit Quest");
  const [btn, setBtn] = useState(false);
  const [airdropId, setAirdropId] = useState("");
  const [level, setLevel] = useState(0);
  const [questTasks, setQuestTasks] = useState([]);
  const [isMineLevelBased, setIsMineLevelBased] = useState({
    mine_id: "",
    levelUp: 0,
  });
  const [isLevelBased, setIsLevelBased] = useState(0);
  const [isReferralBased, setIsReferralBased] = useState({
    referralCount: 0,
    increment: null,
  });

  const editQuest = async () => {
    try {
      setBtn(true);
      setBtnTxt("Editing...");
      const data = {
        quest_id: questId,
        airdrop_id: airdropId,
        level,
        questTask: questTasks,
        isMineLevelBased,
        isReferralBased,
        isLevelBased,
      };
      console.log({ data });
      const { data: response } = await quest.editQuest(data);
      if (response) {
        alert("Quest edited successfully");
      } else {
        alert("Something went wrong.");
      }
      setBtn(false);
      setBtnTxt("Edit Quest");
    } catch (error) {
      const errorMessage = quest.handleTheError(error);
      alert(errorMessage);
      console.log({ errorMessage });
      setBtn(false);
      setBtnTxt("Edit Quest");
    }
  };

  const formatDateTimeLocal = (date) => {
    const date_ = date || Date.now();
    return new Date(date_).toISOString().slice(0, 16);
  };

  const getQuest = async () => {
    try {
      const { data: response } = await quest.getQuestById(questId);
      if (response) {
        const questData = response;
        setAirdropId(questData.airdrop_id);
        setLevel(questData.level);
        setIsLevelBased(questData?.isLevelBased || 0);
        setIsMineLevelBased(
          questData?.isMineLevelBased || {
            mine_id: "",
            levelUp: 0,
          }
        );
        setIsReferralBased(
          questData?.isReferralBased || {
            referralCount: 0,
            increment: null,
          }
        );
        console.log({ response });
        setQuestTasks(questData.questTask);
      }
    } catch (error) {
      const errorMessage = quest.handleTheError(error);
      alert(errorMessage);
      console.log({ errorMessage });
    }
  };

  useEffect(() => {
    getQuest();
  }, [questId]);

  const handleQuestTaskChange = (index, field, value) => {
    const updatedTasks = [...questTasks];
    updatedTasks[index][field] = value;
    setQuestTasks(updatedTasks);
  };

  const addQuestTask = () => {
    setQuestTasks([
      ...questTasks,
      {
        quest_task_id: "",
        text: "",
        action: "TELEGRAM",
        timer: "",
        link: "",
        referralCount: 0,
        btn: "Join",
      },
    ]);
  };

  const removeQuestTask = (index) => {
    const updatedTasks = questTasks.filter((_, idx) => idx !== index);
    setQuestTasks(updatedTasks);
  };

  return (
    <form className="max-w-sm mx-auto mt-40">
      <p className="text-white font-bold text-center text-[40px] mb-2">
        Edit Quest
      </p>
      <div className="mb-2">
        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          Airdrop ID
        </label>
        <input
          type="text"
          value={airdropId}
          onChange={(e) => setAirdropId(e.target.value)}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        />
      </div>
      <div className="mb-2">
        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          Level
        </label>
        <input
          type="number"
          value={level}
          onChange={(e) => setLevel(parseFloat(e.target.value))}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        />
      </div>

      <div className="m-2 mt-4 mb-4 border rounded-lg">
        <label className="block m-2 mb-2 text-sm font-medium text-gray-900 dark:text-white">
          Lock by Mine Level
        </label>
        <div className="mb-2 m-2">
          <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Mine ID
          </label>

          <input
            type="text"
            value={isMineLevelBased?.mine_id}
            onChange={(e) =>
              setIsMineLevelBased({
                ...isMineLevelBased,
                mine_id: e.target.value,
              })
            }
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
        </div>

        <div className="mb-2 m-2">
          <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Level Of Mine
          </label>
          <input
            type="number"
            value={isMineLevelBased?.levelUp}
            onChange={(e) =>
              setIsMineLevelBased({
                ...isMineLevelBased,
                levelUp: parseInt(e.target.value, 10),
              })
            }
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
        </div>
      </div>

      <div className="m-2 mt-4 mb-4 border rounded-lg">
        <label className="block m-2 mb-2 text-sm font-medium text-gray-900 dark:text-white">
          Lock by Referral Count
        </label>
        <div className="mb-2 m-2">
          <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Referral Count
          </label>

          <input
            type="number"
            value={isReferralBased?.referralCount}
            onChange={(e) =>
              setIsReferralBased({
                ...isReferralBased,
                referralCount: parseInt(e.target.value),
              })
            }
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
        </div>
      </div>

      <div className="m-2 mt-4 mb-4 border rounded-lg">
        <label className="block m-2 mb-2 text-sm font-medium text-gray-900 dark:text-white">
          Lock by User Level
        </label>
        <div className="mb-2 m-2">
          <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Minimum Level
          </label>

          <input
            type="number"
            value={isLevelBased}
            onChange={(e) => setIsLevelBased(parseInt(e.target.value, 10))}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
        </div>
      </div>

      <div className="mb-8">
        <p className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          Quest Tasks
        </p>
        {questTasks.map((task, index) => (
          <div
            key={index}
            className="mb-4 border m-2 bg-gray-800 p-2 rounded-lg"
          >
            <div className="mb-2">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                Task ID
              </label>
              <input
                type="text"
                value={task.quest_task_id}
                onChange={(e) =>
                  handleQuestTaskChange(index, "quest_task_id", e.target.value)
                }
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </div>
            <div className="mb-2">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                Text
              </label>
              <input
                type="text"
                value={task.text}
                onChange={(e) =>
                  handleQuestTaskChange(index, "text", e.target.value)
                }
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </div>

            <div className="mb-2">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                Button Text
              </label>
              <input
                type="text"
                value={task.btn}
                onChange={(e) =>
                  handleQuestTaskChange(index, "btn", e.target.value)
                }
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </div>

            <div className="mb-2">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                Action
              </label>
              <select
                value={task.action}
                onChange={(e) =>
                  handleQuestTaskChange(index, "action", e.target.value)
                }
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              >
                <option value="TELEGRAM">TELEGRAM</option>
                <option value="TWITTER">TWITTER</option>
                <option value="INSTAGRAM">INSTAGRAM</option>
                <option value="REFERRAL">REFERRAL</option>
                <option value="CLAIM">CLAIM REWARD</option>
                <option value="TON_CONNECT">TON CONNECT</option>
                <option value="EVM_CONNECT">EVM CONNECT</option>
              </select>
            </div>

            {task?.action == "REFERRAL" ? (
              <>
                <input
                  type="number"
                  name="referralCount"
                  value={task.referralCount}
                  placeholder="How many referrals"
                  onChange={(e) =>
                    handleQuestTaskChange(
                      index,
                      "referralCount",
                      e.target.value
                    )
                  }
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 mb-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </>
            ) : (
              <></>
            )}

            <input
              type="number"
              name="timer"
              value={task.timer}
              placeholder="Timer"
              onChange={(e) =>
                handleQuestTaskChange(index, "timer", e.target.value)
              }
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 mb-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />

            <input
              type="text"
              name="link"
              value={task.link}
              placeholder="Link to task"
              onChange={(e) =>
                handleQuestTaskChange(index, "link", e.target.value)
              }
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 mb-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />

            <button
              type="button"
              onClick={() => removeQuestTask(index)}
              className="text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm w-full px-5 py-2.5 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
            >
              Remove Task
            </button>
          </div>
        ))}

        <button
          type="button"
          onClick={addQuestTask}
          className="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm w-full px-5 py-2.5 text-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
        >
          Add Task
        </button>
      </div>

      <button
        type="button"
        disabled={btn}
        onClick={editQuest}
        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
      >
        {btnTxt}
      </button>
    </form>
  );
}

export default QuestEdit;
