import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "buffer";
// import { Address, beginCell, Cell, toNano } from "@ton/ton";
import {
  TonConnectUIProvider,
  TonConnectButton,
  useTonAddress,
  useTonConnectUI,
  toUserFriendlyAddress,
  useTonConnectModal,
} from "@tonconnect/ui-react";
import { Distribution } from "../connect/distribution.connect";
const NOTCOIN_CONTRACT_ADDRESS =
  "EQAvlWFDxGF2lXm67y4yzC17wYKD9A0guwPkMs1gOsM__NOT";
const MY_ADDRESS = "UQB8clOuiM7I5Y9nH08TGKkw_jdfVYixvyTaxFL4Q69Ql1d8";
const MY_JETTON_WALLET = "EQAanE844VRJSlZVDnegs_8ouQ3R39wYnHLx16Ifx8DpFPVu";
const distribution = new Distribution();
const OPT_CODE = 0x0f8a7ea5;
const BOC_EXAMPLE = "te6cckECBgEAAb8AAuOIAPjkp10RnZHLHs4+niYxUmH8br6rEWN+SbWIpfCHXqEuBjxwCOBvKHbduW5axRb+DDJTHsQ5HpoezYBRYDg4fBYShHU5sWDLg+EvJCaBdOKXN72SzPVhbdzyGr8RWkQlADFNTRi7N+iX0AAAALAAGBwDAQFoYgANTieccKokpSsqhzvQWf+UXIbo7+4MTjl469EP4+B0iiAX14QAAAAAAAAAAAAAAAAAAQIBsg+KfqUAAAAAAAAAAFAlQL5ACAHMieVgdSMz2pzhJ7LhXKBaS6mw4BrWC0k1h0S+bgnmqwAfHJTrojOyOWPZx9PExipMP43X1WIsb8k2sRS+EOvUJcgL68IABQFoYgANTieccKokpSsqhzvQWf+UXIbo7+4MTjl469EP4+B0iiAX14QAAAAAAAAAAAAAAAAAAQQBsg+KfqUAAAAAAAAAAFAlQL5ACAAcQf++quTBWockr6nVwrCt2KF0p9b25LhbvXKqzJc5LQAfHJTrojOyOWPZx9PExipMP43X1WIsb8k2sRS+EOvUJcgL68IABQBCAAAAAEFpcmRyb3AgZnJvbSBDbGFuIG9mIFJhaWRlcnMhpU0qTQ==";

const WalletComponent = () => {
  const { airdropId: airdrop_id } = useParams();
  const [walletConnected, setWalletConnected] = useState(false);
  const [totalUsers, setTotalUsers] = useState(0);
  const [users, setUsers] = useState(null);
  const [symbol, setSymbol] = useState("");
  const [percentage, setPercentage] = useState(60);
  const [transactions, setTransactions] = useState(null);
  const [loading, setLoading] = useState(false);
  const [address, setAddress] = useState(useTonAddress());
  const [tonConnectUI] = useTonConnectUI();
  const { state, open, close } = useTonConnectModal();
  const [bocString, setBocString] = useState("");
  const [bocLoading, setBocLoading] = useState(false);
  const [copiedTransactions, setCopiedTransactions] = useState([]);

  const getAirdropDetailsForTx = async () => {
    const { data } = await distribution.getAirdropDetailsForTx(
      airdrop_id,
      percentage
    );

    console.log({ data });
    setTotalUsers(data?.totalUsers ?? 0);
    setUsers(data?.users);
    setSymbol(data?.airdrop?.symbol);
    const tx = await data?.users?.map((user) => {
      return {
        username: user?.userDetail?.username || "",
        address: user?.userDetail?.walletAddress,
        amount: user?.amount * 10,
        isCopied: false,
        telegramId: user?.telegramId,
      };
    });
    setTransactions(tx);
    makeCheck(tx);
  };

  const makeCheck = (tx) => {
    const savedCopiedTransactions =
      JSON.parse(localStorage.getItem("copiedTransactions")) || [];
    setCopiedTransactions(savedCopiedTransactions);
    setTransactions(
      tx.map((item) => ({
        ...item,
        isCopied: savedCopiedTransactions.some(
          (copiedItem) => copiedItem.telegramId == item.telegramId
        ),
      }))
    );
  };

  useEffect(() => {
    getAirdropDetailsForTx();
  }, []);

  useEffect(() => {
    if (address.length) {
      setWalletConnected(true);
      // setAddress(toUserFriendlyAddress(address));
    }
  }, [address]);

  useEffect(() => {
    console.log({ state });
    if (state?.closeReason == "wallet-selected") {
      setWalletConnected(true);
      setAddress(address);
    }
  }, [state]);

  const confirmTx = async () => {
    const { data } = await distribution.confirmTxByAdmin(airdrop_id);
    console.log({ data, message: "Tx confirmed" });
  };

  const approveTx = async () => {
    const { data } = await distribution.approveTx(users);
    console.log({ data, message: "Tx Approved" });
  };

  // const formatToCSV = () => {
  //   // const boc = BOC_EXAMPLE;
  //   // const block = Cell.fromBoc(Buffer.from(boc, "base64"))[0];
  //   // console.log({ block })
  //   return transactions.filter((x) => x.address !== null ).map((item) => `${item.address}, ${60}`).join("\n");
  // };

  const formatToCSV = () => {
    const filteredTransactions = transactions.filter((x) => x.address !== null);
    const numberOfCopies = Math.floor(222 / filteredTransactions.length);
    const repeatedTransactions = filteredTransactions
      .map((item) => {
        return Array(numberOfCopies).fill(`${item.address}, ${60}`).join("\n");
      })
      .join("\n");

    return repeatedTransactions;
  };

  const copyToClipboard = () => {
    const csvData = formatToCSV();
    navigator.clipboard
      .writeText(csvData)
      .then(() => {
        alert("CSV data copied for MultiSender");
      })
      .catch((error) => {
        console.error("Failed to copy: ", error);
      });
  };

  const copyToClipboardAddress = (data) => {
    const address = data.address;
    const telegramId = data.telegramId;
    navigator.clipboard
      .writeText(address)
      .then(() => {
        const updatedTransactions = transactions.map((item) =>
          item.telegramId == telegramId ? { ...item, isCopied: true } : item
        );
        setTransactions((prevTransactions) =>
          prevTransactions.map((item) =>
            item.telegramId == telegramId ? { ...item, isCopied: true } : item
          )
        );
        const newCopiedTransactions = updatedTransactions.filter(
          (item) =>
            item.isCopied &&
            !copiedTransactions.some(
              (copiedItem) => copiedItem.telegramId === item.telegramId
            )
        );
        const updatedCopiedTransactions = [
          ...copiedTransactions,
          ...newCopiedTransactions,
        ];
        setCopiedTransactions(updatedCopiedTransactions);
        localStorage.setItem(
          "copiedTransactions",
          JSON.stringify(updatedCopiedTransactions)
        );
      })
      .catch((error) => {
        console.error("Failed to copy: ", error);
      });
  };

  const sendTransactions = async () => {
    if (transactions) {
      setLoading(true);
      try {
        const messages = [];
        // const jettonWalletAddress = Address.parse(MY_JETTON_WALLET);
        // const messages = transactions
        //   .filter((tx) => tx.address !== null)
        //   .map((tx) => {
        //     const recipientAddress = Address.parse(tx.address);
        //     const value = toNano("10");
        //     const amount = toNano(tx.amount.toString());
        //     const OPT_CODE = 0x0f8a7ea5;
        //     const forwardPayload = beginCell()
        //       .storeUint(0, 32)
        //       .storeStringTail("Airdrop from Clan of Raiders!")
        //       .endCell();
        //     const transferMsg = beginCell()
        //       .storeUint(OPT_CODE, 32)
        //       .storeUint(0, 64)
        //       .storeCoins(value)
        //       .storeAddress(recipientAddress)
        //       .storeAddress(Address.parse(MY_ADDRESS))
        //       .storeUint(0, 1)
        //       .storeCoins(toNano("0.10"))
        //       .storeBit(0)
        //       .storeRef(forwardPayload)
        //       .endCell();

        //     return {
        //       address: jettonWalletAddress.toString(),
        //       amount: toNano("0.05").toString(),
        //       payload: transferMsg.toBoc().toString("base64"),
        //     };
        //   });

        const transactionRequest = {
          validUntil: Math.floor(Date.now() / 1000) + 36000,
          messages,
        };
        console.log({ messages });
        const response = await tonConnectUI.sendTransaction(transactionRequest);
        console.log({ sendTransactions: transactions, response });

        if (response) {
          await confirmTx();
          alert("Transaction was successful, admin approved");
          if (response.boc) {
            await approveTx();
            const boc = response.boc;
            console.log({ boc });
            alert("Transaction was successful, Transactions approved");
          }
        } else {
          alert("Transaction failed.");
        }
      } catch (error) {
        console.error("Transaction Error:", error);
        if (error.code == 300)
          return alert(`An error occurred, ${error.message}`);
      } finally {
        setLoading(false);
      }
    }
  };

  const disconnectWallet = () => {
    if (tonConnectUI.connected) {
      tonConnectUI.disconnect();
      setWalletConnected(false);
    }
  };

  const confirmBoc = async () => {
    try {
      if (bocString.length) {
        setBocLoading(true);
        const { data } = await distribution.confirmBoc(airdrop_id, bocString);
        if (data) {
          alert("Boc confirmed");
          setBocString("");
          console.log({ bocResult: data });
        }
      }
    } catch (error) {
      console.log({ error });
      setBocString("");
    }
    setBocLoading(false);
  };

  return (
    <div className="max-w-md mx-auto mt-10">
      <p className="text-white font-bold text-center text-[40px] mb-2">
        TON Wallet & NotCoin Distribution
      </p>

      <div className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg p-4 dark:bg-gray-700 dark:border-gray-600 dark:text-white">
        {!walletConnected ? (
          <TonConnectButton
            onConnect={() => setWalletConnected(true)}
            className="w-full bg-green-700 text-white rounded-lg p-2 mb-4 hover:bg-green-800"
          />
        ) : (
          <>
            <div className="flex justify-between mb-4">
              <button
                onClick={sendTransactions}
                className={`w-1/2 bg-blue-700 text-white rounded-lg p-2 mr-2 ${
                  loading
                    ? "opacity-50 cursor-not-allowed"
                    : "hover:bg-blue-800"
                }`}
                disabled={loading}
              >
                {loading ? "Sending..." : "Send NotCoin"}
              </button>
              <button
                onClick={() => copyToClipboard()}
                className="w-1/2 bg-green-700 text-white rounded-lg p-2 ml-2 hover:bg-green-800"
              >
                Copy tx
              </button>

              <button
                onClick={() => disconnectWallet()}
                className="w-1/2 bg-red-700 text-white rounded-lg p-2 ml-2 hover:bg-red-800"
              >
                Disconnect Wallet
              </button>
            </div>
            <div className="flex justify-between mb-4">
              <input
                onChange={(e) => setBocString(e.target.value)}
                className={`w-1/2 text-white rounded-lg p-2 mr-2 bg-transparent border`}
              />
              <button
                onClick={confirmBoc}
                className={`w-1/2 bg-blue-700 text-white rounded-lg p-2 mr-2 ${
                  bocLoading
                    ? "opacity-50 cursor-not-allowed"
                    : "hover:bg-blue-800"
                }`}
                disabled={bocLoading}
              >
                {bocLoading ? "requesting..." : "Confirm Boc"}
              </button>
            </div>

            <div className="flex justify-between mb-4">
              <span className="font-bold text-white">
                Total tx: {transactions?.length || 0}
              </span>
              <span className="font-bold text-white">
                Disconnected:{" "}
                {transactions?.filter((tx) => tx.address == null)?.length || 0}
              </span>{" "}
              <span className="font-bold text-white">
                Completed tx: {copiedTransactions.length}
              </span>
            </div>
          </>
        )}

        {totalUsers.length && (
          <p className="font-bold">
            Total qualified users
            <span className="p-2 mt-2">{totalUsers}</span>
          </p>
        )}

        {/* Transactions Display */}
        <div className="bg-gray-100 dark:bg-gray-800 rounded-lg p-4">
          <p className="font-semibold text-lg mb-2">Transactions:</p>
          <ul className="space-y-2">
            {transactions &&
              transactions.map((tx, index) => (
                <li key={index} className="border-b border-gray-300 pb-2">
                  <p className="text-sm">
                    Username:{" "}
                    <span className="font-semibold">{tx.username}</span>
                  </p>
                  <p className="text-sm">
                    Address:{" "}
                    <span
                      onClick={() => !tx.isCopied && copyToClipboardAddress(tx)}
                      className="font-semibold cursor-pointer"
                    >
                      {tx?.address && tx.address.length > 15
                        ? `${tx.address.substring(
                            0,
                            10
                          )}...${tx.address.substring(tx.address.length - 10)}`
                        : tx?.address}
                    </span>{" "}
                    <span className="text-green-400 text-sm font-bold ml-1">
                      {tx.isCopied ? "copied" : ""}
                    </span>
                  </p>
                  <p className="text-sm">
                    Amount:{" "}
                    <span className="font-semibold">
                      {tx.amount.toLocaleString()} {symbol.length && symbol}
                    </span>
                  </p>
                </li>
              ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

const DistributePage = () => {
  return (
    <TonConnectUIProvider manifestUrl="https://api-staging.clanofraiders.com/tonconnect-manifest.json">
      <WalletComponent />
    </TonConnectUIProvider>
  );
};

export default DistributePage;
