import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { ProjectConnect } from "../../connect/project.connect";

function CreateQuest() {
  const quest = new ProjectConnect();
  const { airdropId } = useParams();
  const [btnTxt, setBtnTxt] = useState("Create Quest");
  const [btn, setBtn] = useState(false);
  const [level, setLevel] = useState(0);
  const [questTasks, setQuestTasks] = useState([
    {
      quest_task_id: "",
      text: "",
      action: "TELEGRAM",
      timer: 0,
      link: "",
      referralCount: 0,
      btn: "Join",
    },
  ]);

  const handleQuestTaskChange = (index, event) => {
    const newQuestTasks = [...questTasks];
    newQuestTasks[index][event.target.name] = event.target.value;
    setQuestTasks(newQuestTasks);
  };

  const addQuestTask = () => {
    setQuestTasks([
      ...questTasks,
      {
        quest_task_id: "",
        text: "",
        action: "TELEGRAM",
        timer: 0,
        link: "",
        referralCount: 0,
        btn: "Join",
      },
    ]);
  };

  const removeQuestTask = (index) => {
    const newQuestTasks = questTasks.filter((_, i) => i !== index);
    setQuestTasks(newQuestTasks);
  };

  const createQuest = async () => {
    try {
      setBtn(true);
      setBtnTxt("Creating...");
      console.log("Airdrop ID:", airdropId);
      console.log("Level:", level);
      console.log("Quest Tasks:", questTasks);

      const data = {
        airdrop_id: airdropId,
        level,
        questTask: questTasks.map((task) => ({
          ...task,
        })),
      };
      console.log({ data });

      const { data: response } = await quest.createQuest(data);
      if (response) {
        alert("Quest created successfully");
      } else {
        alert("Something went wrong.");
      }
      setBtn(false);
      setBtnTxt("Create Quest");
    } catch (error) {
      const errorMessage = quest.handleTheError(error);
      alert(errorMessage);
      console.log({ errorMessage });
      setBtn(false);
      setBtnTxt("Create Quest");
    }
  };

  return (
    <form className="max-w-sm mx-auto mt-40">
      <p className="text-white font-bold text-center text-[40px] mb-2">
        Create Quest
      </p>

      <div className="mb-2">
        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          Level
        </label>
        <input
          type="number"
          value={level}
          onChange={(e) => setLevel(parseFloat(e.target.value))}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        />
      </div>
      {questTasks.map((task, index) => (
        <div key={index} className="mb-2 p-2 bg-gray-800 m-2">
          <input
            type="text"
            name="text"
            value={task.text}
            onChange={(e) => handleQuestTaskChange(index, e)}
            placeholder="Task Text"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 mb-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />

          <input
            type="text"
            name="quest_task_id"
            value={task.quest_task_id}
            onChange={(e) => handleQuestTaskChange(index, e)}
            placeholder="Task ID"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 mb-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />

          <input
            type="text"
            name="btn"
            value={task.btn}
            onChange={(e) => handleQuestTaskChange(index, e)}
            placeholder="Button Text"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 mb-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />

          <select
            name="action"
            value={task.action}
            onChange={(e) => handleQuestTaskChange(index, e)}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 mb-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          >
             <option value="TELEGRAM">TELEGRAM</option>
                <option value="TWITTER">TWITTER</option>
                <option value="INSTAGRAM">INSTAGRAM</option>
                <option value="REFERRAL">REFERRAL</option>
                <option value="CLAIM">CLAIM REWARD</option>
                <option value="TON_CONNECT">TON CONNECT</option>
                <option value="EVM_CONNECT">EVM WALLET</option>
          </select>

          {task?.action == "REFERRAL" ? (
            <>
              {" "}
              <input
                type="number"
                name="referralCount"
                value={task.referralCount}
                placeholder="How many referrals"
                onChange={(e) => handleQuestTaskChange(index, e)}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 mb-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </>
          ) : (
            <></>
          )}
          <input
            type="number"
            name="timer"
            value={task.timer}
            placeholder="Timer in millsec"
            onChange={(e) => handleQuestTaskChange(index, e)}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 mb-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />

          <input
            type="text"
            name="link"
            value={task.link}
            placeholder="Link to task"
            onChange={(e) => handleQuestTaskChange(index, e)}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 mb-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
          <button
            type="button"
            onClick={() => removeQuestTask(index)}
            className="text-red-600 hover:text-red-800 focus:outline-none"
          >
            Remove Task
          </button>
        </div>
      ))}

      <button
        type="button"
        onClick={addQuestTask}
        className="text-blue-600 hover:text-blue-800 focus:outline-none"
      >
        Add Task
      </button>
      <button
        type="button"
        disabled={btn}
        onClick={createQuest}
        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 mt-4"
      >
        {btnTxt}
      </button>
    </form>
  );
}

export default CreateQuest;
