import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import './App.css';
import Project from './pages/project';
import Level from './pages/level';
import Reward from './pages/reward';
import LevelList from './components/level/LevelList';
import LevelEdit from './components/level/LevelEdit';
import ProjectEdit from './components/airdrop/ProjectEdit';
import Login from './pages/Login';
import UserList from './components/users/userList';
import RegisterAdmin from './pages/Register';
import { useEffect } from 'react';
import GetUser from './components/users/getUser';
import Referral from './components/users/referral';
import AirdropEdit from './components/project/AirdropEdit';
import AirdropCreate from './components/project/createAirdrop';
import Airdrops from './pages/airdropList';
import QuestList from './pages/questList';
import QuestEdit from './components/quest/questEdit';
import CreateQuest from './components/quest/createQuest';
import MarketPlace from './pages/marketPlace';
import CreateMine from './components/mine/createMine';
import EditMine from './components/mine/editMine';
import DailyComboList from './pages/dailyCombo';
import CreateDailyReward from './components/dailycombo/CreateDailyCombo';
import EditCombo from './components/dailycombo/DailyComboEdit';
import DistributionList from './pages/distributionList';
import DistributePage from './pages/distributionPage';
function App() {

  return (
    <div className="">
      <Router>
        <Routes>
          <Route path='/' element={<Navigate to='/login' replace />} />
          <Route path='/p' element={<Project />}/>
          <Route exact path='/login' element={<Login />}/>
          <Route path='/register' element={<RegisterAdmin />}/>
          <Route path='/users' element={<UserList />}/>
          <Route path='/user/:identifier' element={<GetUser />}/>
          <Route path='/referral/:telegramId' element={<Referral />}/>
          <Route path='/level/:projectId' element={<Level />}/>
          <Route path='/levels/:projectId' element={<LevelList />}/>
          <Route path='/level/edit/:level_id' element={<LevelEdit />}/>
          <Route path='/project/:project_id' element={<ProjectEdit />}/>
          <Route path='/airdrop/:airdrop_id' element={<AirdropEdit />} />
          <Route path='/airdrop' element={<AirdropCreate />} />
          <Route path='/quest/:airdropId' element={<QuestList/>} />
          <Route path='/quest/edit/:questId' element={<QuestEdit/>} />
          <Route path='/quest/add/:airdropId' element={<CreateQuest/>} />
          <Route path='/reward' element={<Reward />}/>
          <Route path='/airdrops' element={<Airdrops />} />
          <Route path='/marketplace' element={<MarketPlace />} />
          <Route path='/item' element={<CreateMine />} />
          <Route path='/dailyCombo' element={<DailyComboList />} />
          <Route path='/dailyCombo/edit/:combo_id' element={<EditCombo />} />
          <Route path='/create-dailyCombo' element={<CreateDailyReward />} />
          <Route path='/item-edit/:mineId' element={<EditMine />} />
          <Route path='/distribution/:airdropId' element={<DistributionList />} />
          <Route path='/distribute-page/:airdropId' element={<DistributePage />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
